<template>
  <v-container fluid>
    <v-row>
      <v-col :align="'center'" :justify="'center'">
          <h1>{{ $t('message.addressNotFound') }}</h1>
      </v-col>
    </v-row>
    <v-img height="80vh" :src="require('../../assets/images/ADDRESS_NOT_FOUND.svg')" contain></v-img>
  </v-container>
</template>

<script>
export default {
  name: 'address-not-found-page',
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss"></style>